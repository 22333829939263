import React, {useState,useEffect} from 'react';
import { Row,Col,Container,Button } from 'react-bootstrap';
import 'react-vertical-timeline-component/style.min.css';
import '../App.css';
import 'aos/dist/aos.css';
// import myCV from '../assert/documents/addjoy_barreto.pdf';
import { PhoneNumber,Address } from '../config';


const AboutMe = () => {
    const [qrcodeimg, setqrcodeimg] = useState('');
    const [age, setage] = useState(24);

    useEffect(() => {
        generateQR();
        calculateAge(new Date('1998-11-26'))
      },[]);
    
      const generateQR = async text => {
        try {
          // console.log(window.localtion);

          // let qrcode = await fetch('https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=https://addjoysportfolio.netlify.app/static/media/addjoy_barreto.58faaaac.pdf').then(res=>res.url)
          // assert/documents/addjoy_barreto.pdf
          let qrcode = await fetch('https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=https://addjoybarreto.com/assert/documents/addjoy_barreto.pdf').then(res=>res.url)
          setqrcodeimg(qrcode);
    
        } catch (err) {
          console.error(err)
        }
      }

      const calculateAge = (birthday) => {
        var ageDifMs = Date.now() - birthday;
        var ageDate = new Date(ageDifMs);
        setage(Math.abs(ageDate.getUTCFullYear() - 1970))
        return Math.abs(ageDate.getUTCFullYear() - 1970);
      }


      const handleDownload = () =>{
        try{
          window.dataLayer.push({
            event: 'buttonClick', // This should match the event name in GTM
            buttonName: 'My Button' // Custom data you want to send
          });
        }catch(err){

        }
      }

  return (
    <div><Container id="AboutMe" fluid className="Aboutme" style={{backgroundColor:'#0B1F37'}}>
    <Row className="innerAboutme">
      <Col className="Profile1img" style={{textAlign:'center'}} data-aos="zoom-in">
      <img src={'images/profile3.jpg?format=webp'} alt="Logo" width={300} height={300} style={{width:'300px',height:'300px',borderRadius:'10px'}} />
        {/* <img src={'./Images/profile1.jpg?format=webp'} alt="Logo" width={300} height={300} style={{width:'300px',height:'300px',borderRadius:'10px'}} /> */}
      </Col>
      <Col className="aboutmeblock px-sm-5" data-aos="zoom-in">
        <Row style={{display:'flex',flexDirection:'column'}}>
          <Col style={{color:'red',fontSize:'5vh',fontWeight:'500',fontFamily:'Roboto'}}>Discover</Col>
          <Col style={{color:'white',fontSize:'6vh',fontWeight:'500',fontFamily:'Roboto'}}>About Me</Col>
        </Row>
        <Row className="discovermedata">
          Experienced Full Stack Software Engineer with 5+ years in Web Development, IoT, Automation, and Microservices. Adept at creating scalable applications with cutting-edge technologies. Passionate problem solver, continuously enhancing skills to lead in innovation.
        </Row>
        <Row style={{border:'1px solid red',margin:'2vh 0',borderRadius:'5px',color:'white',display:'flux',fluxWrap:'wrap',fontFamily:'Roboto'}}>
          <Row className="tableRow">
            <Col className="aboutboxinput" >Name: Addjoy Barreto</Col>
            <Col className="aboutboxinput">Age: {age}</Col>
          </Row>
          <Row className="tableRow" >
            <Col className="aboutboxinput">Phone: {PhoneNumber}</Col>
            <Col className="aboutboxinput">Address: {Address}</Col>
          </Row>
          <Row className="tableRow">
            {/* <Col className="aboutboxinput">Specialization: Web dev, IOT, Automation</Col> */}
            <Col className="aboutboxinput">Degree: B.E. CS</Col>
          </Row>
          <Row className="tableRow">
            <Col className="aboutboxinput">Specialization: Web Development, IOT, Automation</Col>
            {/* <Col className="aboutboxinput">Degree: B.E. CS</Col> */}
          </Row>
        </Row>
        <Row style={{color:'white',fontSize:'1em',paddingLeft:'20px'}}>
          <Col>
            <a href={`./assert/documents/addjoy_barreto.pdf`} download="addjoy_barreto_cv" style={{textDecoration:'none'}}>
              <Button variant="danger" style={{outline:'none'}} onClick={()=>handleDownload()}>Download CV</Button> 
            </a> 
          </Col>
          <Col>
            <div className={"g-container"}>
                <img src={qrcodeimg} alt={"QRcode"} width={100} height={100}/>
            </div>
          </Col>
           
        </Row>
      </Col>
    </Row>
  </Container></div>
  )
}

export default AboutMe;
