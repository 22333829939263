import React,{useState} from 'react';
import { Row,Container, ProgressBar } from 'react-bootstrap';
import 'react-vertical-timeline-component/style.min.css';
import '../App.css';
import 'aos/dist/aos.css'; // You can also use <link> for styles

const Skills =() =>{
    const [skills, setskills] = useState([
        {name:'WEB DEV',score: 90},
        {name:'MOBILE DEV',score: 80},
        {name:'REACT JS',score: 90},
        {name:'NODEJS',score:90},
        {name:'PYTHON',score:70},
        {name:'IOT',score:80},
        {name:'AUTOMATION',score:80},
        {name:'MONGO DB',score:90},
        {name:'MYSQL',score:80},
    ])

  return (
    <Container id="Skills" fluid className="Skillsetwindow">
    <Row className="skillTopsection" data-aos="zoom-in">
      <span className="MyAwesomeSkill">My Awesome Skill</span>
      <span className="MySkill">My Skills</span>
    </Row>
    <Row className="skillblock">
        {
            skills.map((s,index)=>(
                <div className="skillsetbox" key={index} data-aos="fade-up">
                    <span className="skillName">{s.name}</span>
                    <ProgressBar role="progressbar" now={s.score} variant="danger" aria-valuenow={s.score} aria-valuemin={"0"} aria-valuemax={"100"} aria-labelledby={s.name} animated={false} style={{backgroundColor:"gray",height:'2px'}}/>
                </div>
            ))
        }
    </Row>
</Container>
  )
}

export default Skills