import React from 'react';
import 'react-vertical-timeline-component/style.min.css';
import './App.css';
import 'aos/dist/aos.css'; // You can also use <link> for styles

import AboutMe from './components/AboutMe';
import Skills from './components/Skills';
import Resume from './components/Resume';
import Footer from './components/Footer';
import Home from './components/Home';


function App() {
  
  return (
    <div className="App">
      <Home />
    {/* about me */}
      <AboutMe />
    {/* skills */}
      <Skills />

    {/* Resume */}  
      <Resume />

    {/* contact me */}
    <Footer />
</div>
  );
}

export default App;
