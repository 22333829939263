import React,{useState,useEffect} from 'react';
import { Navbar, Nav} from 'react-bootstrap';
import 'react-vertical-timeline-component/style.min.css';
import '../App.css';
import { Link } from 'react-scroll';
import FontAwesome from 'react-fontawesome';
import 'aos/dist/aos.css'; // You can also use <link> for styles



const NavBar = () => {
    const [navlist, setnavlist] = useState([
        {to:'Home',name: 'Home'},
        {to:'AboutMe',name: 'About Me'},
        {to:'Skills',name: 'Skills'},
        {to:'Resume',name: 'Resume'},
        {to:'ContactMe',name: 'Contact Me'},
    ])

    const handleSetActive = (e) =>{
        document.getElementsByClassName(`${e}1`)[0].style.color = 'red';
      }
    
      const handleSetInactive = (e) =>{
        document.getElementsByClassName(`${e}1`)[0].style.color = 'white';
      }
      
    
      useEffect(() => {
        window.addEventListener("scroll", e => {
          var scrolled = document.scrollingElement.scrollTop;
          var scrolled1 = document.body.scrollTop;
          if (scrolled >= 8  || scrolled1 >=8) {
              document.getElementsByClassName('navBar')[0].style.background = "rgba(0,0,0,0.4)";
          }
          else {
              document.getElementsByClassName('navBar')[0].style.background = "none";
        }
      });
      },[]);

  return (
    <div className='topNav'>
        <Navbar collapseOnSelect expand="lg" className='navBar' fixed="top">
        <Navbar.Brand href="#home" style={{fontWeight:'lighter',color:'white'}}>Addjoy's Portfolio</Navbar.Brand>
        <Navbar.Toggle style={{border:'none',outline:'none',margin:'auto 3vw',width: '100px', height: '40px'}} > <FontAwesome className="super-crazy-colors" name="bars" size="2x" style={{color:'#ffffff' }}/></Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav" style={{justifyContent:'flex-end',margin:'auto 3vw'}}>
            <Nav className="mc-auto">
          {
            navlist.map((n,index)=>(
                <Link activeClass="active"
                to={n.to}
                href={n.to}
                key={index.toString()}
                spy={true}
                smooth={true}
                hashSpy={true}
                offset={0}
                duration={500}
                isDynamic={true}
                ignoreCancelEvents={false}
                onSetActive={(e)=>handleSetActive(e)}
                onSetInactive={(e)=>handleSetInactive(e)}
                className={`navItem ${n.to}1`}
                style={{color:'white',textDecoration:'none'}}
          >
            {n.name}
          </Link>
            ))
          }
            </Nav>
        </Navbar.Collapse>
        </Navbar>
    </div> 
  )
}

export default NavBar