import React,{useState} from 'react';
import { Container } from 'react-bootstrap';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import '../App.css';
import FontAwesome from 'react-fontawesome';
import 'aos/dist/aos.css'; // You can also use <link> for styles


const Resume = () => {
    // const [resumelist, setresumelist] = useState([
    //    {date:'April 2022 - now',title:`Full Stack Software Development | Iot | Automation | BlockChain`,location:`Dubai, UAE`,detail:`I'm currently working as A Full Stack Software Developer at Innobayt Inovation Solutions.`,fonticonname:'building', contentArrowStyle: '7px solid  rgb(33, 150, 243)',contentStyle:{background:'rgb(33, 150, 243)',color:'#fff'},iconStyle: {background:'rgb(33, 150, 243)',color:'#fff'},paracolor:'#fff'},
    //    {date:'Oct 2020 - Dec 2021',title:`Full Stack Software Development`,location:`Goa, India`,detail:` I worked as a Software Developer at Intuio Software Labs Pvt. Ltd. on various Web and Mobile Applications.`,fonticonname:'building', contentArrowStyle: '7px solid  #fff',contentStyle:{background:'',color:'#bbb'},iconStyle: {background:'rgb(233, 30, 99)',color:'#fff'},paracolor:'#000'},
    //    {date:'2016 - 2020',title:`Bachelor's in Computer Science`,location:`Goa, India`,detail:`I Graduated with my CS Bachelor's degree from Padre Conceicao College of Engineering, Goa.`,fonticonname:'graduation-cap', contentArrowStyle: '7px solid #fff',contentStyle:{background:'',color:'#bbb'},iconStyle: {background:'rgb(233, 30, 99)',color:'#fff'},paracolor:'#000'},
    //    {date:'Jan 2019',title:`Internship`,location:`Goa, India`,detail:`Worked at DIGISOL Systems Ltd. and built a simple Visitors App.`,fonticonname:'building', contentArrowStyle: '7px solid #fff',contentStyle:{background:'',color:'#bbb'},iconStyle: {background:'rgb(233, 30, 99)',color:'#fff'},paracolor:'#000'},
    //    {date:'July 2018',title:`Internship`,location:`Goa, India`,detail:`Worked at Chowgule Brothers Pvt. Ltd. on Data Management.`,fonticonname:'building', contentArrowStyle: '7px solid #fff',contentStyle:{background:'',color:'#bbb'},iconStyle: {background:'rgb(233, 30, 99)',color:'#fff'},paracolor:'#000'},
    //    {date:'2014 - 2016',title:`Science Stream`,location:`Goa, India`,detail:`Graduated from Rosary Higher Secondary school with a focus on Science during the 11th and 12th grade.`,fonticonname:'graduation-cap', contentArrowStyle: '7px solid #fff',contentStyle:{background:'',color:'#bbb'},iconStyle: {background:'rgb(33, 150, 243)',color:'#fff'},paracolor:'#000'},
    //    {date:'2002 - 2014',title:`High School`,location:`Galgibaga, Goa, India`,detail:`Studied till my 10th Grade at St. Anthony's HS and was an All House Captain.`,fonticonname:'graduation-cap', contentArrowStyle: '7px solid #fff',contentStyle:{background:'',color:'#bbb'},iconStyle: {background:'rgb(33, 150, 243)',color:'#fff'},paracolor:'#000'},
    // ])
    const [resumelist, setresumelist] = useState([
      {date:'2022 - now',title:`Full Stack Software Development | Iot | Automation | BlockChain`,location:`Dubai, UAE`,detail:`I'm currently working as A Full Stack Software Developer at Innobayt Inovation Solutions.`,fonticonname:'building', contentArrowStyle: '7px solid  rgb(33, 150, 243)',contentStyle:{background:'rgb(33, 150, 243)',color:'#fff'},iconStyle: {background:'rgb(33, 150, 243)',color:'#fff'},paracolor:'#fff'},
      {date:'2020 - 2021',title:`Full Stack Software Development`,location:`Goa, India`,detail:` I worked as a Software Developer at Intuio Software Labs Pvt. Ltd. on various Web and Mobile Applications.`,fonticonname:'building', contentArrowStyle: '7px solid  #fff',contentStyle:{background:'',color:'#bbb'},iconStyle: {background:'rgb(233, 30, 99)',color:'#fff'},paracolor:'#000'},
      {date:'2016 - 2020',title:`Bachelor's in Computer Science`,location:`Goa, India`,detail:`I Graduated with my CS Bachelor's degree from Padre Conceicao College of Engineering, Goa.`,fonticonname:'graduation-cap', contentArrowStyle: '7px solid #fff',contentStyle:{background:'',color:'#bbb'},iconStyle: {background:'rgb(233, 30, 99)',color:'#fff'},paracolor:'#000'},
      {date:'Jan 2019 - Feb 2019',title:`Internship`,location:`Goa, India`,detail:`Worked at DIGISOL Systems Ltd. and built a simple Visitors App.`,fonticonname:'building', contentArrowStyle: '7px solid #fff',contentStyle:{background:'',color:'#bbb'},iconStyle: {background:'rgb(233, 30, 99)',color:'#fff'},paracolor:'#000'},
      {date:'June 2018 - July 2018',title:`Internship`,location:`Goa, India`,detail:`Worked at Chowgule Brothers Pvt. Ltd. on Data Management.`,fonticonname:'building', contentArrowStyle: '7px solid #fff',contentStyle:{background:'',color:'#bbb'},iconStyle: {background:'rgb(233, 30, 99)',color:'#fff'},paracolor:'#000'},
      {date:'2014 - 2016',title:`Science Stream`,location:`Goa, India`,detail:`Graduated from Rosary Higher Secondary school with a focus on Science during the 11th and 12th grade.`,fonticonname:'graduation-cap', contentArrowStyle: '7px solid #fff',contentStyle:{background:'',color:'#bbb'},iconStyle: {background:'rgb(33, 150, 243)',color:'#fff'},paracolor:'#000'},
      {date:'2002 - 2014',title:`High School`,location:`Galgibaga, Goa, India`,detail:`Studied till my 10th Grade at St. Anthony's HS and was an All House Captain.`,fonticonname:'graduation-cap', contentArrowStyle: '7px solid #fff',contentStyle:{background:'',color:'#bbb'},iconStyle: {background:'rgb(33, 150, 243)',color:'#fff'},paracolor:'#000'},
   ])

  return (
    <Container fluid id="Resume" className="Skillsetwindow" style={{paddingTop:'40px',paddingBottom:'40px'}}>
      <div style={{width:'90vw',alignItems:'center',marginBottom:'40px'}} data-aos="zoom-in">
        <div style={{color:'red',fontSize:'5vh',fontWeight:'500',textAlign:'center',fontFamily:'Roboto'}}>Experience and Education</div>
        <div style={{color:'white',fontSize:'6vh',fontWeight:'500',textAlign:'center',fontFamily:'Roboto'}}>My Resume</div>
      </div>
      <VerticalTimeline>
        { 
            resumelist.map((r,index)=>(
                <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date={r.date}
                key={index.toString()}
                contentStyle={{ background: `${r.contentStyle.background}`, color: `${r.contentStyle.color}` }}
                contentArrowStyle={{ borderRight: `${r.contentArrowStyle}` }}
                iconStyle={{ background: `${r.iconStyle.background}`, color: `${r.iconStyle.color}` }}
                icon={<FontAwesome name={r.fonticonname} size="2x" style={{color:'#fff',width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}/>}
              >
                <h3 className="vertical-timeline-element-title" style={{color: r.paracolor}}>{r.title}</h3>
                <h4 className="vertical-timeline-element-subtitle" style={{color: r.paracolor}}>{r.location}</h4>
                <p style={{color: r.paracolor}}>
                  {r.detail} 
                </p>
              </VerticalTimelineElement>
            ))
        }
        <VerticalTimelineElement
          iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}   
        />
      </VerticalTimeline>
    </Container>
  )
}

export default Resume