import React from 'react';
import { Row,Container } from 'react-bootstrap';
import 'react-vertical-timeline-component/style.min.css';
import '../App.css';
import FontAwesome from 'react-fontawesome';
import 'aos/dist/aos.css';

const Footer = () => {
  return (
    <Container fluid id="ContactMe" className="Skillsetwindow" style={{paddingTop:'40px',paddingBottom:'80px'}}>
      <Row data-aos="zoom-in">
        <div style={{width:'80vw',marginBottom:'40px'}} >
          <div style={{color:'white',paddingLeft:'3vw',fontSize:'6vh',fontWeight:'500',fontFamily:'Roboto'}}>Contact Me</div>
        </div>
      </Row>
    {/* contact box section */}
    <Row style={{width:'80vw',display:'flex',fluxWrap:'wrap',justifyContent:'center',paddingBottom:'8vh'}}>
      {/* each contact block */}
      <div className="contactboxes" data-aos="fade-up" onClick={()=> window.open("https://www.google.com/maps/place/Canacona,+Goa", "_blank")}>
        <div style={{display:'flex',flexDirection:'column',width:'60%',justifyContent:'center'}}>
          <span style={{color:'white',fontSize:'1.2em',fontWeight:'500',paddingLeft:'1.5vw'}}>Loaction</span>
          <span style={{color:'white',paddingLeft:'1.5vw'}}>Canacona, Goa, India.</span>
        </div>
        <div style={{display:'flex',alignItems:'center'}}>
          <FontAwesome className="super-crazy-colors" name="map-o" size="4x" style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)',color:'#ff5151' }}/>
        </div>
      </div>

      <div className="contactboxes" data-aos="fade-up" onClick={()=> window.open("mailto:addjoybarreto26@gmail.com", "_blank")}>
        <div style={{display:'flex',flexDirection:'column',width:'60%',justifyContent:'center'}}>
          <span style={{color:'white',fontSize:'1.2em',fontWeight:'500',paddingLeft:'1.5vw'}}>Gmail</span>
          <span style={{color:'white',paddingLeft:'1.5vw'}}>addjoybarreto</span>
          <span style={{color:'white',paddingLeft:'1.5vw'}}>26@gmail.com</span>
        </div>
        <div style={{display:'flex',alignItems:'center'}}>
          <FontAwesome className="super-crazy-colors" name="envelope-o" size="4x" style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)',color:'#ff5151' }}/>
        </div>
      </div>

      <a href="tel:+971507287851" style={{textDecoration:'none'}}>
      <div className="contactboxes"  data-aos="fade-up">
        <div style={{display:'flex',flexDirection:'column',width:'60%',justifyContent:'center'}}>
          <span style={{color:'white',fontSize:'1.2em',fontWeight:'500',paddingLeft:'1.5vw'}}>Phone</span>
          <span style={{color:'white',paddingLeft:'1.5vw'}}>+971 507287851</span>
        </div>
        <div style={{display:'flex',alignItems:'center'}}>
          <FontAwesome className="super-crazy-colors" name="phone" size="4x" style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)',color:'#ff5151' }}/>
        </div>
      </div>
      </a>

      <div className="contactboxes" data-aos="fade-up" onClick={()=> window.open("https://www.linkedin.com/in/addjoybarreto/", "_blank")}>
        <div style={{display:'flex',flexDirection:'column',width:'60%',justifyContent:'center'}}>
          <span style={{color:'white',fontSize:'1.2em',fontWeight:'500',paddingLeft:'1.5vw'}}>Linkedin</span>
        </div>
        <div style={{display:'flex',alignItems:'center'}}>
          <FontAwesome className="super-crazy-colors" name="linkedin" size="4x" style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)',color:'#ff5151' }}/>
        </div>
      </div>

      <div className="contactboxes" data-aos="fade-up" onClick={()=> window.open("https://github.com/AddjoyBarreto", "_blank")}>
        <div style={{display:'flex',flexDirection:'column',width:'60%',justifyContent:'center'}}>
          <span style={{color:'white',fontSize:'1.2em',fontWeight:'500',paddingLeft:'1.5vw'}}>Github</span>
        </div>
        <div style={{display:'flex',alignItems:'center'}}>
          <FontAwesome className="super-crazy-colors" name="github" size="4x" style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)',color:'#ff5151' }}/>
        </div>
      </div>

      <div className="contactboxes" data-aos="fade-up" onClick={()=> window.open("https://twitter.com/AddjoyBarreto", "_blank")}>
        <div style={{display:'flex',flexDirection:'column',width:'60%',justifyContent:'center'}}>
          <span style={{color:'white',fontSize:'1.2em',fontWeight:'500',paddingLeft:'1.5vw'}}>Twitter</span>
        </div>
        <div style={{display:'flex',alignItems:'center'}}>
          <FontAwesome className="super-crazy-colors" name="twitter" size="4x" style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)',color:'#ff5151' }}/>
        </div>
      </div>

      <div className="contactboxes" data-aos="fade-up" onClick={()=> window.open("https://www.fiverr.com/lukeborito", "_blank")}>
        <div style={{display:'flex',flexDirection:'column',width:'60%',justifyContent:'center'}}>
          <span style={{color:'white',fontSize:'1.2em',fontWeight:'500',paddingLeft:'1.5vw'}}>Fiverr</span>
        </div>
        <div style={{display:'flex',alignItems:'center'}}>
          <FontAwesome className="super-crazy-colors" name="fa-solid fa-laptop" size="4x" style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)',color:'#ff5151' }}/>
        </div>
      </div>
    </Row>
    </Container>
  )
}

export default Footer