import React from 'react';
import { Row,Container} from 'react-bootstrap';
import 'react-vertical-timeline-component/style.min.css';
import '../App.css';
import 'aos/dist/aos.css'; // You can also use <link> for styles

import NavBar from './NavBar';

const Home = () => {
  return (
    <div id="Home" className="homeBlock">
    <img src={'./Images/blue_landscape_mirror.jpg?format=webp'} alt="Logo" className='startImage' />
    <NavBar /> 

    <div className='topProfileBlock'>
        <Container className='topProfile' data-aos="fade-up">
          <Row>
            {/* <img src={'./Images/profile1.jpg?format=webp'} alt="Logo" className='Profileimg' /> */}
            <img src={'images/profile3.jpg?format=webp'} alt="Logo" className='Profileimg' />
          </Row>        
        <Row className="Topnamespace topsectionname">Addjoy Barreto</Row>
        <Row className="Topnamespace topsectionnamedata">Software Engineer</Row>
        </Container>  
    </div> 
</div>
  )
}

export default Home